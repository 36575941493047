<template>
    <Layout>
        <table>
            <tr>
                <th>Reference Number {{ item.ref_no }}</th>
            </tr>

            <tr>
                <td>Owner/Tenant</td>
                <td>{{ item.user_name }}</td>
            </tr>
            <tr>
                <td>Unit Number</td>
                <td>{{ item.unit_no }}</td>
            </tr>
            <tr>
                <td>Mobile Number</td>
                <td>{{ item.mobile }}</td>
            </tr>
            <tr>
                <td>Available Date</td>
                <td>{{ item.available_date }}</td>
            </tr>
            <tr>
                <td>Complaints</td>
                <td>
                    <p
                        v-for="(complaint, index) in item.complains"
                        :key="complaint.id"
                    >
                        {{ index + 1 }}&nbsp;&nbsp;{{ complaint.description }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>Submitted Date</td>
                <td>{{ item.created_at }}</td>
            </tr>
        </table>
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            filename="amendment"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <table>
                    <tr>
                        <th>Reference Number : {{ item.ref_no }}</th>
                    </tr>

                    <tr>
                        <td>Owner/Tenant</td>
                        <td>{{ item.user_name }}</td>
                    </tr>
                    <tr>
                        <td>Unit Number</td>
                        <td>{{ item.unit_no }}</td>
                    </tr>
                    <tr>
                        <td>Mobile Number</td>
                        <td>{{ item.mobile }}</td>
                    </tr>
                    <tr>
                        <td>Available Date</td>
                        <td>{{ item.available_date }}</td>
                    </tr>
                    <tr>
                        <td>Complaints</td>
                        <td>
                            <p
                                v-for="(complaint, index) in item.complains"
                                :key="complaint.id"
                            >
                                {{ index + 1 }}&nbsp;&nbsp;{{
                                    complaint.description
                                }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>Submitted Date</td>
                        <td>{{ item.created_at }}</td>
                    </tr>
                </table>
            </section>
        </VueHtml2pdf>
        <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <b-button variant="primary" block @click="generateReport"
                    >Download PDF</b-button
                >
            </div>
            <div class="col-lg-4"></div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import { BASE_URL } from "../../common";
import VueHtml2pdf from "vue-html2pdf";

export default {
    components: {
        Layout,
        VueHtml2pdf,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.getDetails(this.$route.params.id);
        }
    },
    data() {
        return {
            requestType: null,
            show: true,
            item: {},
        };
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        getDetails(id) {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}print/maintenances/${id}?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data.response);
                        this.show = false;
                        this.item = res.data.response;
                    });
            }
        },
    },
};
</script>
<style scoped>
table {
    margin: 20px;
    width: 80%;
}
td,
tr {
    border: 1px solid black;
}
</style>
